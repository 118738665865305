import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Timeline from './components/Timeline';
import Map from './components/Map';
import styled from 'styled-components';

const App = () => {
  return (
    <Router>
      {/* Sempre renderiza o menu principal */}
      <MainContent>
        <Routes>
          <Route path="/" element={<Map />} />
          <Route path="/map" element={<Map />} />
          <Route path="/timeline" element={<Timeline />} />
        </Routes>
      </MainContent>

            {/* Adicionando a logo flutuante no topo direito */}
            <img src="/img/logo-oside.jpg" alt="Logo OSID" className="floating-logo" />

      {/* Menu de navegação principal, sempre presente */}
      <MainMenu>
        <NavItem>
          <StyledLink to="/map">Mapa</StyledLink>
        </NavItem>
        <NavItem>
          <StyledLink to="/timeline">Timeline</StyledLink>
        </NavItem>
      </MainMenu>
    </Router>
  );
};

export default App;

const MainContent = styled.div`
  padding-bottom: 60px; /* Garantindo espaço para o menu no rodapé */
`;

const MainMenu = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #333;
  color: #fff;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  z-index: 1000;
`;

const NavItem = styled.div`
  font-size: 1.2rem;
`;

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  &:hover {
    color: #ddd;
  }
`;
