import React, { useRef, useState, useEffect } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { motion } from 'framer-motion';
import styled, { keyframes, css } from 'styled-components';
import EventIcon from './EventIcon'; // Ícones personalizados

const data = [
  {
    id: '1914',
    date: '1914',
    title: 'Nascimento',
    description: 'Maria Rita era filha de dona Dulce Maria de Souza Brito e do doutor Augusto Lopes Pontes, dentista e professor da Universidade Federal da Bahia. Quando criança, costumava rezar muito e pedia sinais a Santo Antônio, pois queria saber se deveria seguir a vida religiosa ou se casar. ',
    mediaType: 'image',
    eventType: 'birth',
    mediaSrc: '/img/nasce-familia.jpg',
    extraImageSrc: '/img/nasce-familia2.jpg' // Imagem extra
  },
  {
    id: '1933',
    date: '1933',
    title: 'Entrada na Vida Religiosa',
    description: 'Em 8 de fevereiro de 1933, logo após se formar professora primária (1932), Maria Rita entrou para a Congregação das Irmãs Missionárias da Imaculada Conceição da Mãe de Deus, na cidade de São Cristóvão, em Sergipe. Em 13 de agosto de 1933, após seis meses de noviciado, ela fez sua profissão de fé e votos perpétuos, tomando o hábito de freira e recebendo o nome de Irmã Dulce, em homenagem a sua mãe, aos 19 anos de idade.',
    eventType: 'religious',
    mediaType: 'image',
    mediaSrc: '/img/convento.jpg',
    extraImageSrc: '/img/convento2.jpg' // Imagem extra
  },
  {
    id: '1939',
    date: '1939',
    title: 'Inauguração do Colégio Santo Antônio',
    description: 'Em maio de 1939, Irmã Dulce inaugurou o Colégio Santo Antônio, voltado para os operários e seus filhos. Acolheu aproximadamente 100 alunos beneficiados no primeiro ano',
    eventType: 'edu',
    mediaType: 'image',
    mediaSrc: '/img/aula_stantonio.jpg',
    extraImageSrc: '/img/aula_stantonio2.jpg' // Imagem extra
  },
  {
    id: '1949',
    date: '1949',
    title: 'Transformação do galinheiro do Convento Santo Antônio',
    description: 'Transformação do galinheiro do Convento Santo Antônio em abrigo para Doentes. 70 pessoas atendidas no princípio.',
    eventType: 'hospital',
    mediaType: 'image',
    mediaSrc: '/img/hospital01.jpg',
    extraImageSrc: '/img/hospital02.jpg' // Imagem extra
  },
  {
    id: '1959',
    date: '1959',
    title: 'Fundação das Obras Sociais',
    description: 'Irmã Dulce fundou as Obras Sociais Irmã Dulce (OSID). Cerca de 1.000 atendimentos médicos realizados no primeiro ano, incluindo consultas, pequenos procedimentos e assistência social. ',
    eventType: 'hospital',
    mediaType: 'image',
    mediaSrc: '/img/cesa.jpg',
    extraImageSrc: '/img/cesa2.jpg' // Imagem extra
  },
  {
    id: '1964',
    date: '1964',
    title: 'Fundação do Centro Educacional Santo Antônio',
    description: 'Matrícula inicial de 300 alunos em educação básica e profissionalizante, além de atividades culturais e esportivas.',
    eventType: 'edu',
    mediaType: 'image',
    mediaSrc: '/img/cesa02.jpg',
    extraImageSrc: '/img/cesa03.jpg' // Imagem extra
  },
  {
    id: '1983',
    date: '1983',
    title: 'Inauguração Oficial do Hospital Santo Antônio',
    description: 'Com capacidade inicial de 400 leitos ele se torna um dos maiores hospitais filantrópicos do país. Realização de aproximadamente 100.000 atendimentos anuais, incluindo consultas, internações, cirurgias e procedimentos especializados.',
    eventType: 'hospital',
    mediaType: 'image',
    mediaSrc: '/img/criancas.jpg',
    extraImageSrc: '/img/hospital03.jpg' // Imagem extra
  },
  {
    id: '1988',
    date: '1988',
    title: 'Indicação ao Prêmio Nobel da Paz',
    description: 'Indicada ao Prêmio Nobel da Paz pelo trabalho humanitário.',
    eventType: 'award',
    mediaType: 'image',
    mediaSrc: '/img/nobel.jpg',
    extraImageSrc: '/img/nobel2.jpg' // Imagem extra
  },
  {
    id: '1992',
    date: '1992',
    title: 'Falecimento',
    description: 'Irmã Dulce faleceu em Salvador, deixando um legado de caridade e amor ao próximo.',
    eventType: 'religious',
    mediaType: 'image',
    mediaSrc: '/img/falecimento.jpg',
    extraImageSrc: '/img/falecimento2.jpg' // Imagem extra
  },
  {
    id: '2019',
    date: '2019',
    title: 'Canonizada',
    description: 'Irmã Dulce foi canonizada em 13 de outubro de 2019 pelo Papa Francisco, tornando-se a primeira santa nascida no Brasil.',
    eventType: 'award',
    mediaType: 'image',
    mediaSrc: '/img/canone.jpg',
    extraImageSrc: '/img/canone2.jpg' // Imagem extra
  },
];
const Timeline = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [layer, setLayer] = useState({}); // Controla o z-index para cada evento
  const [rotate, setRotate] = useState({}); // Controla a rotação de cada evento

  const refs = useRef(data.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {}));

  const handleScroll = () => {
    const offsets = data.map((event) => {
      const ref = refs.current[event.id];
      if (ref && ref.current) {
        const boundingRect = ref.current.getBoundingClientRect();
        return { id: event.id, top: boundingRect.top };
      }
      return { id: event.id, top: Number.MAX_SAFE_INTEGER };
    });

    const visibleEvent = offsets.find(({ top }) => top >= 0 && top <= window.innerHeight / 2);
    if (visibleEvent) {
      const index = data.findIndex(event => event.id === visibleEvent.id);
      setActiveIndex(index);
    }
  };

  const scrollToEvent = (index) => {
    const ref = refs.current[data[index].id];
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
      setActiveIndex(index);
    }
  };

  const handleRotate = (id, target) => {
    setRotate((prevState) => ({
      ...prevState,
      [id]: { ...prevState[id], [target]: true },
    }));

    setTimeout(() => {
      setRotate((prevState) => ({
        ...prevState,
        [id]: { ...prevState[id], [target]: false },
      }));
    }, 400); // A animação dura 400ms no total
  };

  const handleLayerToggle = (id) => {
    setLayer((prevState) => ({
      ...prevState,
      [id]: prevState[id] === 'media' ? 'extra-media' : 'media',
    }));
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <NavMenu>
        {data.map((event, index) => (
          <NavItem
            key={event.id}
            active={index === activeIndex}
            onClick={() => scrollToEvent(index)}
          >
            {event.date}
          </NavItem>
        ))}
      </NavMenu>

      <StyledTimeline>
        <VerticalTimeline>
          {data.map((event, index) => (
            <div key={index} className="timeline-wrapper">
              <VerticalTimelineElement
                date={
                  <>
                    {/* Ordem de exibição controlada pelo estado "layer" */}
                    <motion.div
                      className="media"
                      style={{
                        zIndex: layer[event.id] === 'media' || !layer[event.id] ? 2 : 1,
                        transform: rotate[event.id]?.media ? 'rotate(3deg)' : 'rotate(0deg)',
                      }}
                      onClick={() => {
                        handleRotate(event.id, 'media');
                        handleLayerToggle(event.id);
                      }}
                      animate={{
                        rotate: rotate[event.id]?.media ? [3, -3, 0] : 0,
                      }}
                      transition={{
                        duration: 0.4,
                        ease: 'easeInOut',
                      }}
                      ref={refs.current[event.id]}
                    >
                      <motion.img
                        src={event.mediaSrc}
                        alt={event.title}
                        style={{ width: '100%' }}
                        whileHover={{ scale: 1.05 }}
                      />
                    </motion.div>

                    <motion.div
                      className="extra-media"
                      style={{
                        zIndex: layer[event.id] === 'extra-media' ? 2 : 1,
                        transform: rotate[event.id]?.extra ? 'rotate(3deg)' : 'rotate(0deg)',
                      }}
                      onClick={() => {
                        handleRotate(event.id, 'extra');
                        handleLayerToggle(event.id);
                      }}
                      animate={{
                        rotate: rotate[event.id]?.extra ? [3, -3, 0] : 0,
                      }}
                      transition={{
                        duration: 0.4,
                        ease: 'easeInOut',
                      }}
                    >
                      <motion.img
                        src={event.extraImageSrc}
                        alt="extra"
                        style={{ width: '100%', maxWidth: '400px' }}
                      />
                    </motion.div>
                  </>
                }
                icon={<EventIcon eventType={event.eventType} />}
                iconStyle={{ background: '#fff', color: '#000' }}
              >
                <div className="text-content">
                  <h2 className="event-date">{event.date}</h2>
                  <h1>{event.title}</h1>
                  <p>{event.description}</p>
                </div>
              </VerticalTimelineElement>
            </div>
          ))}
        </VerticalTimeline>
      </StyledTimeline>
    </>
  );
};

export default Timeline;

const dropDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledTimeline = styled.div`
  background-image: url('/img/1.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  font-family: 'Vollkorn', serif;

  .timeline-wrapper {
    position: relative;
  }

  .vertical-timeline-element-content {
    margin-top: 1rem;
    margin-bottom: 3rem;
    position: relative;
    z-index: 2;
  }

  .vertical-timeline-element-date {
    top: 0; /* Alterado de 6 para 0 */
  }

  .vertical-timeline-element {
    min-height: 500px;
    margin-top: 90px;
    position: relative;
  }

  .text-content {
    padding: 16px;
    transition: transform 0.2s;
    &:hover {
      transform: scale(1.05);
    }
  }

  .media {
    margin-bottom: 1rem;
    background: rgba(240, 240, 245, 0.9);
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 2px -1px 3px 1px #00000038;
    rotate: 2deg;
    position: relative;
    cursor: pointer; /* Cursor interativo */
  }

  .extra-media {
    background: rgba(240, 240, 245, 0.9);
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    max-width: 500px;
    width: 100%;
    position: absolute;
    margin-top: -450px;
    rotate: -10deg;
    z-index: 1;
    margin-left: 90px;
    box-shadow: 2px -1px 3px 1px #00000038;
    cursor: pointer; /* Cursor interativo */
  }

  .event-date {
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
  }
`;

const NavMenu = styled.div`
  position: fixed;
  bottom: 43px; /* Altere aqui: move o menu de datas 50px acima do menu principal */
  width: 100%;
  background: #333;
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 4px 0;
  overflow-x: auto;
  white-space: nowrap;
  font-family: 'Allerta Stencil', sans-serif;
  z-index: 1000; /* Certifique-se de que ele não sobreponha o principal */
`;

const NavItem = styled.div`
  margin: 0 15px;
  padding: 0.3rem;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.6rem;
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  transition: opacity 0.3s;
  box-shadow: ${({ active }) => (active ? '2px 2px 2px 2px black' : 'none')};
  border-radius: ${({ active }) => (active ? '2px' : '0')};
  ${({ active }) =>
    active &&
    css`
      animation: ${dropDown} 0.5s ease-out;
    `}
  &:hover {
    color: #ddd;
  }
`;