import React from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet'; // Importação do Leaflet
import bahiaData from './bahia.json'; // Assumindo que você já tem o GeoJSON correto da Bahia

// Coordenadas e informações dos hospitais
const hospitals = [
  {
    name: "Sede Principal – Complexo Roma",
    shortName: "Sede",
    location: [-12.936, -38.511],
    city: "Salvador",
    since: 1959,
    offset: [0.7, 0.4],
    description: "Localizado na Cidade Baixa, Península Itapagipana, o Complexo Roma surgiu de um simples galinheiro, no Convento Santo Antônio, que em 1949 foi ocupado por Santa Dulce dos Pobres para cuidar de pessoas em situação de vulnerabilidade social. O Complexo possui 40 mil metros quadrados de área construída, onde se concentram 20 dos 21 núcleos de atendimentos da instituição, dentre eles o Hospital Santo Antônio, Centro Geriátrico, Hospital da Criança, Unidade de Alta Complexidade em Oncologia Nossa Senhora de Fátima, Centro de Acolhimento à Pessoa com Deficiência, CMSALP – Centro Médico Social Augusto Lopes Pontes e o CATA - Centro de Acolhimento e Tratamento de Alcoolistas. É também onde está localizado o Complexo Santuário Santa Dulce dos Pobres, local de espiritualidade e acolhimento aos fiéis, devotos, pacientes, colaboradores e visitantes em geral.",
    photo: "/img/img01.jpg"
  },
  {
    name: "Centro Educacional Santo Antônio",
    shortName: "C. Educacional",
    location: [-12.791, -38.403],
    city: "Simões Filho",
    since: 1964,
    offset: [0.2, 0.8],
    description: "Onde funciona o 21o núcleo de atendimento da instituição voltado à educação. A sede foi doada em 1964 pelo Governo do Estado da Bahia. Inicialmente abrigou crianças e adolescente, em regime de internato, que Santa Dulce retirava das ruas ou eram deixados aos seus cuidados por famílias carentes. Atende atualmente 950 crianças e adolescentes em situação de vulnerabilidade social, oferecendo ensino em tempo integral do primeiro ao nono ano (Ensino Fundamental I e II), em parceria com as Secretarias de Educação do Estado e do Município. Onde também estão localizados o Centro de Panificação e o Convento do Instituto Filhas de Maria Servas dos Pobres.",
    photo: "/img/img02.jpg"
  },
  {
    name: "Hospital do Oeste",
    shortName: "H. do Oeste",
    location: [-12.145, -44.998],
    city: "Barreiras",
    since: 2006,
    offset: [0, 0],
    description: "Em junho de 2006, as Obras Sociais Irmã Dulce assumiram a administração do Hospital do Oeste, construído pelo governo do estado no município de Barreiras, a 870 km de Salvador. O hospital conta com uma unidade de Urgência e Emergência funcionando 24 horas; 193 leitos de internação. A unidade reúne em seu quadro de atendimento um total de 20 especialidades.",
    photo: "/img/img03.jpg"
  },
  {
    name: "Hospital Eurídice Sant’anna",
    shortName: "H. Eurídice",
    location: [-11.011, -44.526],
    city: "Santa Rita de Cássia",
    since: 2008,
    offset: [0, 0],
    description: "A unidade presta assistência também à população das cidades de Formosa do Rio Preto, Riachão das Neves e Mansidão, uma das regiões mais carentes da Bahia, além de atender pacientes do sul do Piauí. Possui 24 leitos. Reúne em sua rede de atendimento um conjunto de seis especialidades e também de serviços de diagnóstico e terapia.",
    photo: "/img/img04.jpg"
  },
  {
    name: "Hospital Regional Dr. Mário Dourado",
    shortName: "H. Irecê",
    location: [-11.305, -41.855],
    city: "Irecê",
    since: 2012,
    offset: [0, 0],
    description: "Situado no centro de Irecê, a cerca de 470 Km de Salvador, o hospital é administrado pela OSID desde agosto de 2012. A unidade é referência em serviços de saúde de média e alta complexidade para mais de 700 mil habitantes de 38 municípios. Presta atendimento de Urgência e Emergência e dispõe de 120 leitos.",
    photo: "/img/img05.jpg"
  },
  {
    name: "Centro de Convivência Irmã Dulce",
    shortName: "CCIDP",
    location: [-12.936, -38.511],
    city: "Salvador",
    since: 2013,
    offset: [0.6, -0.8],
    description: "Localizado no Centro Histórico de Salvador, a unidade tem como foco a assistência às pessoas em sofrimento psíquico e em vulnerabilidade social pertencentes ao território da Península Itapagipana e Centro Histórico, especialmente aos usuários de substâncias psicoativas, além do atendimento às famílias residentes no bairro e clientes referenciados pelo Sistema Único de Saúde.",
    photo: "/img/img06.jpg"
  },
  {
    name: "Hospital Regional de Juazeiro",
    shortName: "H. Juazeiro",
    location: [-9.416, -40.505],
    city: "Juazeiro",
    since: 2020,
    offset: [0, 0],
    description: "O HRJ está apto para o atendimento de alta complexidade, possuindo serviço de diagnose e terapia, ambulatório eletivo de especialidades, pronto atendimento (urgências), internação, serviços de apoio assistencial, inclusive internação domiciliar e atividades de ensino e pesquisa.",
    photo: "/img/img01.jpg"
  },
  {
    name: "UPA Santo Antônio",
    shortName: "UPA St Antônio",
    location: [-12.948, -38.464],
    city: "Salvador",
    since: 2021,
    offset: [0.1, -0.5],
    description: "A unidade disponibiliza atendimento de Urgência e Emergência em regime de 24 horas e oferece serviços nas áreas de Medicina Clínica, Pediatria, Ortopedia, Psiquiatria, Cirurgia Geral, Enfermagem, Odontologia, Serviço Social, Farmácia e Serviços de Apoio Diagnóstico.",
    photo: "/img/img07.jpg"
  },
  {
    name: "Unidade Patamares",
    shortName: "Patamares",
    location: [-12.948, -38.399],
    city: "Salvador",
    since: 2021,
    offset: [-0.2, 1],
    description: "Prédio recebido de doação do Sr. Edilson Celestino da Silva e da Sra. Tereza M. B. da Silva, composto por quatro pavimentos e dois anexos, construído em um terreno de mais de 12 mil metros quadrados, localizado na Avenida Tamburugy, no bairro de Patamares. Neste local funcionam atualmente, o ambulatório do Centro Especializado em Reabilitação - CER IV, com atuação nas temáticas auditiva, física, intelectual e visual, o Centro de Pesquisa Clínica e uma parte dos núcleos de apoio administrativo da instituição.",
    photo: "/img/img08.jpg"
  },
  {
    name: "Hospital de Paulo Afonso",
    shortName: "H. Paulo Afonso",
    location: [-9.406, -38.215],
    city: "Paulo Afonso",
    since: 2022,
    offset: [0, 0],
    description: "A unidade dispõe de 60 leitos, sendo 50 de Clínica Médica e Cirúrgica, e 10 leitos de Unidade de Terapia Intensiva (UTI). O HRPA também oferece exames de imagem, como ultrassonografia e eletrocardiograma, e ambulatório para pacientes egressos de cirurgias.",
    photo: "/img/img09.jpg"
  },
  {
    name: "Hospital Municipal do Homem",
    shortName: "H. do Homem",
    location: [-12.973, -38.502],
    city: "Salvador",
    since: 2022,
    offset: [-0.4, 0],
    description: "Unidade hospitalar voltada aos cuidados da saúde do público masculino.",
    photo: "/img/img10.jpg"
  }
];

// Cores fixas para os marcadores
const markerColors = ['#1E90FF', '#4682B4', '#2F4F4F', '#808080', '#DAA520', '#8B4513'];

// Função para criar ícones de marcadores personalizados
const createCustomIcon = (shortName, color) => {
  return new L.DivIcon({
    className: 'custom-div-icon',
    html: `
      <div style="background-color:${color}; color:white; padding:8px; border-radius:5px;
                  min-width:100px; text-align:center; box-shadow:2px 2px 5px rgba(0, 0, 0, 0.5);">
        ${shortName}
      </div>`,
    iconSize: [120, 42],
    iconAnchor: [60, 42]
  });
};

// Estilo para a linha da Bahia e os municípios
const bahiaStyle = {
  color: '#808080', // Linha cinza sólida
  weight: 1,
  dashArray: '', // Remover o pontilhado
  fillOpacity: 0
};

// Estilo para os municípios destacados
const highlightedStyle = {
  fillColor: 'orange', // Amarelo alaranjado
  color: '#808080', // Cor da borda cinza
  fillOpacity: 0.7
};

// Função para definir o estilo dos municípios com hospitais
const getFeatureStyle = (feature) => {
  const municipiosComHospitais = [
    "Salvador", "Barreiras", "Santa Rita de Cássia", "Irecê", "Juazeiro", "Paulo Afonso"
  ];

  if (municipiosComHospitais.includes(feature.properties.name)) {
    return highlightedStyle;
  }

  return bahiaStyle;
};

const Maps = () => {
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <MapContainer
        center={[-12.97, -38.51]}
        zoom={7}
        minZoom={6}
        maxZoom={10}
        style={{ height: "100%", width: "100%" }}
      >
        {/* Mapa com estilo simplificado */}
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />

        {/* Exibir o GeoJSON da Bahia com destaque para os municípios */}
        <GeoJSON data={bahiaData} style={getFeatureStyle} />

        {/* Adicionar marcadores personalizados com offset */}
        {hospitals.map((hospital, index) => {
          const markerPosition = [
            hospital.location[0] + hospital.offset[0],
            hospital.location[1] + hospital.offset[1]
          ];
          const lineCoordinates = [hospital.location, markerPosition]; // Linha entre localização original e offset

          return (
            <React.Fragment key={index}>
              {/* Linha entre a posição original e a nova posição com offset */}
              <Polyline positions={lineCoordinates} color="black" weight={1} />
              <Marker
                position={markerPosition}
                icon={createCustomIcon(hospital.shortName, markerColors[index % markerColors.length])}
              >
                <Popup>
                  <strong>{hospital.name}</strong><br />
                  Cidade: {hospital.city}<br />
                  Desde: {hospital.since}<br />
                  <p>{hospital.description}</p>
                  <img src={hospital.photo} alt={hospital.name} style={{ width: '100%', height: 'auto' }} />
                </Popup>
              </Marker>
            </React.Fragment>
          );
        })}
      </MapContainer>
    </div>
  );
};

export default Maps;
